:root {
  --color-neon-purple: #6F4EFB;
  --color-night-purple: #100959;
  --color-young-cherry: #E92D52;
  --color-background: #100959;
  --color-text: #FFFFFF;

  /* TO REMOVE */
  --color-beige: #FBF7ED;
  --color-blue: #02E1FF;
  --color-green: #10FF70;
  /* end to remove */

  --color-grey: #898989;
  --color-white: #FFFFFF;
  --color-black: #121212;
  --color-input-error: #FF3945;
}


html {
  -moz-osx-font-smoothing     : grayscale;
  -webkit-font-smoothing      : antialiased;
  -webkit-tap-highlight-color : transparent;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  /* This variable is setup in the /app/layout.tsx file */
  font-family: var(--font-satoshi), sans-serif;
  font-size: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  width: 100vw;
  width: 100dvw;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  min-height: 100%;
  overscroll-behavior-x: none;
}

html, body,
nav, header, main, footer,
section, aside, article, div,
img, caption, figure, figcaption, video, audio, progress,
h1, h2, h3, h4, h5, h6, hgroup
p, span, small, strong,
ol, ul, li,
dl, dt, dd,
blockquote,
form, fieldset, legend, label,
table, tbody, tfoot, thead, tr, th, td,
pre, abbr, address, cite, summary, code,
del, dfn, em, ins, kbd, q, samp, sub, sup, var, time, mark {
  background: transparent;
  border: 0;
  direction: ltr;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  min-height: 100vh;
  min-height: 100dvh;
  position: relative;
}

html,
body {
  font-size: 3.6231884057971016vw;
}

@media (min-width: 603px) {
  html,
  body {
    font-size: 2.487562189054726vw;
  }
}

@media (min-width: 768px) {
  html,
  body {
    font-size: 1.25vw;
  }
}

@media (min-width: 1440px) {
  html,
  body {
    font-size: 18px;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

.srt-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

button {
  appearance: none;
  border: 0;
  font-family: inherit;
  padding: 0;
}

button,
a {
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  outline: 0;
}

a {
  text-decoration: none;
}

a:active,
a:hover,
a:focus,
button:active,
button:hover,
button:focus {
  outline: 0;
}

p,
span,
small,
strong {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

li {
  list-style-type: none;
}

img {
  -ms-interpolation-mode: bicubic;
  transform: translate3d(0, 0, 0);
  vertical-align : middle;
  width: 100%;
}

figure,
figure img {
  margin: 0;
}

input:active,
textarea:active,
input:focus,
textarea:focus,
input:hover,
textarea:hover {
  outline: 0;
}

input {
  background-color: var(--color-background);
  border: 0;
  border-radius: 0;
  caret-color: inherit;
  cursor: pointer;
  margin-bottom: 0;
}


input::-webkit-caps-lock-indicator,
input::-webkit-credentials-auto-fill-button,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button {
  display: none;
  position: absolute;
  right: 0;
  visibility: hidden;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input:-webkit-autofill {
  content: '\feff';
}

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizelegibility;
  font-weight: 400;
}

p {
  color: inherit;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
}

small {
  font-size: 12px;
}

ol,
ul,
dl,
dd,
address {
  margin-bottom : 0;
}

address {
  font-style: normal;
}

dl {
  outline: 0;
}

dt {
  font-weight: bold;
}

blockquote  {
  border-left: 10px solid var(--color-text);
  font-style: normal;
  margin: 0;
  padding: 0 15px;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted var(--color-text);
}

b,
strong {
  font-weight : bold;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

sup {
  right: .2em;
  top: .2em;
}

sub {
  bottom: -.25em;
}

body.slider-being-dragged .flickity-viewport a,
body.slider-being-dragged .flickity-viewport button {
  pointer-events: none !important;
}

.slider--flickify {
  outline: none;
}

.flickity-viewport {
  max-height: 100%;
  position: relative;
  touch-action: pan-y;
  width: 100%;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-slider {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: flex-start;
  position: absolute;
  width: 100%;
}

.flickity-slider > .flickity-cell {
  display: inline-block;
  grid-row: 1 / span 1;
  position: absolute;
  width: fit-content;
}

.fade .flickity-viewport,
.fade .flickity-slider {
  transform: none !important;
}

.fade .flickity-cell {
  opacity: 0;
  transform: none !important;
  transition: 600ms opacity ease-in-out !important;
  z-index: auto;
}

.fade .flickity-cell.is-selected {
  opacity: 1;
  transform: none !important;
  transition: 600ms opacity ease-in-out !important;
  z-index: 2;
}


.global-err {
  align-items: center;
  background-color: var(--color-light-grey);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  justify-content: center;
  margin: 0 auto;
  overflow: auto;
  width: 100vw;
}

@media (min-width: 768px) {
  .global-err {
    height: 100vh;
  }
}

.global-err hgroup {
  margin: 180px auto;
  max-width: 80vw;
}

.global-err h1 {
  margin-bottom: 20px;
  font-family: var(--font-satoshi);
  font-size: 35px;
  line-height: 38px;
  font-weight: 600;
}

.global-err p,
.global-err button,
.global-err a {
  font-size: 30px;
  line-height: 32px;
  font-weight: 400;
}

.global-err a {
  text-decoration: underline;
}


